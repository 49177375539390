import * as PropTypes from 'prop-types';

const deprecated = (propType, explanation) => {
    const loggedTypeFailures = {};

    return function validate(props, propName, componentName, ...rest) { // Note ...rest here
        if (props[propName] != null) {
            const message = `Warning: "${propName}" property of "${componentName}" has been deprecated.\n${explanation}`;
            if (!loggedTypeFailures[message]) {
                console.error(message);
                loggedTypeFailures[message] = true;
            }
        }

        return propType(props, propName, componentName, ...rest); // and here
    };
};

export default {
    ...PropTypes,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    promise: PropTypes.shape({
        then: PropTypes.func.isRequired,
        catch: PropTypes.func.isRequired
    }),
    // eslint-disable-next-line react/forbid-prop-types
    map: PropTypes.object,
    deprecated
};
