import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export enum PortalMode {
    prepend = "prepend",
    append = "append"
};

interface PortalProps {
    mode: PortalMode,
    container: string
};

const Portal : FC<PortalProps> = ({ mode = PortalMode.append, container, children }) => {
    const rootNode = document.querySelector(container);

    const [portalRoot, setPortalRoot] = useState(rootNode);

    useEffect(() => {
        if (mode === PortalMode.prepend) {
            const portalAnchor = document.createElement("div");

            portalRoot.prepend(portalAnchor);

            setPortalRoot(portalAnchor);

            return () => {
                portalRoot.removeChild(portalAnchor);
                setPortalRoot(rootNode);
            }
        }
    }, [mode]);

    return createPortal(children, portalRoot);
}

export default Portal;
