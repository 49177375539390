import React from 'react';
import PropTypes from 'prop-types';
import deprecate from '../../utilities/deprecate';

export const propTypes = {
    'test-id': PropTypes.string,
    'data-testid': PropTypes.string,
};

export default (required = true) => (Component) => {
    /* eslint-disable-next-line consistent-return */
    const testIdPropValidator = ({ 'test-id': testId, 'data-testid': dataTestId }) => {
        if (testId && dataTestId) {
            return new Error('You should not combine `test-id` and `data-testid` props, using data-testid.');
        }

        if (testId && !dataTestId) {
            deprecate('test-id prop', '`test-id` prop is deprecated in favor of `data-testid` will be dropped in the next version of BUI.');
        }

        if (required) {
            if (!testId && !dataTestId) {
                return new Error('`data-testid` is a required property for this component, please provide a unique value for automated testing.');
            }
        }
    };

    const testableComponent = (props) => {
        const { 'test-id': testId, 'data-testid': dataTestId, ...p } = props;

        const finalTestId = dataTestId || testId;

        if (finalTestId) {
            return <Component data-testid={finalTestId} {...p} />;
        }

        return <Component {...p} />;
    };

    testableComponent.propTypes = {
        'test-id': PropTypes.string,
        'data-testid': testIdPropValidator,
        ...Component.propTypes
    };

    testableComponent.defaultProps = {
        'test-id': undefined,
        'data-testid': undefined,
        ...Component.defaultProps
    };

    testableComponent.displayName = `testable(${Component.displayName || Component.name || ''})`;

    return testableComponent;
};
