import React, { ButtonHTMLAttributes, FC } from 'react';
import { Logger } from '../../internal';

import { prependClass, appendClass } from '../../utilities/classNames';
import Testable from '../../interfaces/Testable';

export const validButtonTypes = ['button', 'submit', 'reset'];

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, Testable {
    primary?: boolean,
    link?: boolean,
    active?: boolean,
    loading?: boolean,
    outline?: boolean
};

const Button : FC<ButtonProps> = ({ children, 'data-testid': testId, primary = false, link = false, outline = false, loading = false, active, role = 'button', type = 'submit', disabled = false, className = '', ...props}) => {
    className = prependClass(className, 'b-button');

    if(!outline && active) {
        Logger.warn('Active state only supported on outline buttons.');
    }

    if (primary) className = appendClass(className, 'b-primary');

    if (link) {
        className = appendClass(className, 'b-link');
        type = 'button';
    }

    if (outline) {
        className = appendClass(className, 'b-outline');

        if (active) {
            className = appendClass(className, 'b-active');
        }
    }

    if (loading) {
        className = appendClass(className, 'b-loading');
        disabled = true;
    }

    return (
        <button className={className} data-testid={testId} disabled={disabled} role={role} aria-pressed={active} type={type /* eslint-disable-line react/button-has-type */} {...props}>{children}</button>
    );
};

export default Button;
