import React from 'react';
import PropTypes from 'prop-types';
import { prependClass } from '../../utilities/classNames';

const RadioButton = (props) => {
    let {
        id
    } = props;
    const {
        label, className, helpText, disabled, ...p
    } = props;

    if (id === '') {
        id = `radio-${Math.random().toString(36).substr(2, 9)}`;
        // eslint-disable-next-line no-console
        console.warn('Future versions of BUI will require all radio buttons to have IDs');
        delete p.id;
    }

    const testId = p['test-id'];
    delete p['test-id'];

    return (
        <div className={prependClass(className, 'b-radio')}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <input
                type="radio"
                id={id}
                disabled={disabled}
                data-testid={testId}
                {...p}
            />
            <label htmlFor={id} className={disabled ? 'b-text-disabled' : ''}>
                {label}
            </label>
            {helpText !== ''
                && <p className="b-pl-large b-mb-mini b-help">{helpText}</p>}
        </div>
    );
};

RadioButton.propTypes = {
    id: PropTypes.string,
    label: PropTypes.node.isRequired,
    className: PropTypes.string,
    helpText: PropTypes.string,
    disabled: PropTypes.bool,
    'test-id': PropTypes.string.isRequired
};

RadioButton.defaultProps = {
    id: '',
    className: '',
    disabled: false,
    helpText: '',
};

export default RadioButton;
