import React from 'react';
import PropTypes from '../../utilities/prop-types';

import { prependClass } from '../../utilities/classNames';

/* eslint-disable-next-line no-unused-vars */
const EventHandler = (e) => {
    // e.target.value
};

export default class FormInput extends React.Component {
    static propTypes = {
        autocomplete: PropTypes.deprecated(PropTypes.oneOf(['on', 'off']), 'Use autoComplete instead.'),
        autoComplete: PropTypes.oneOf(['on', 'off']),
        placeholder: PropTypes.string,
        'aria-describedby': PropTypes.string.isRequired,
        type: PropTypes.oneOf(['color', 'email', 'radio', 'text', 'number', 'range', 'search', 'tel']),
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        className: PropTypes.string,
        'test-id': PropTypes.string.isRequired,
    };

    static defaultProps = {
        autocomplete: 'on',
        autoComplete: 'on',
        placeholder: '',
        name: undefined,
        value: undefined,
        children: undefined,
        onChange: EventHandler,
        onBlur: EventHandler,
        onFocus: EventHandler,
        type: 'text',
        className: ''
    };

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    render() {
        const {
            // eslint-ignore-next-line react/prop-types
            children, className, 'test-id': testId, autoComplete, autocomplete, ...p
        } = this.props;

        const autoCompleteProp = (autoComplete === 'off' || autocomplete === 'off') ? 'off' : 'on';

        const css = prependClass('b-form-control', className);

        if (children) {
            return (
                <div className="b-input-group">
                    <input ref={input => this.input = input /* eslint-disable-line no-return-assign */} className={css} data-testid={testId} autoComplete={autoCompleteProp} {...p} />
                    {children}
                </div>
            );
        }
        return (
            <input ref={input => this.input = input /* eslint-disable-line no-return-assign */} className={css} data-testid={testId} {...p} />
        );
    }
}


export const Prepend = (props) => {
    const { children, className, ...p } = props;
    const css = prependClass(className, 'b-prepend');

    return <div {...p} className={css}>{children}</div>;
};

Prepend.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};
Prepend.defaultProps = {
    children: '',
    className: ''
};

export const Append = (props) => {
    const { children, className, ...p } = props;
    const css = prependClass(className, 'b-append');

    return <div {...p} className={css}>{children}</div>;
};

Append.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};
Append.defaultProps = {
    children: '',
    className: ''
};
