export default (what, helptext, link) => {
    let message = `"${what}" is deprecated.`;

    if (helptext) {
        message = `${message}\n ${helptext}`;
    }

    if (link) {
        message = `${message}\n More Information: ${link}`;
    }

    // eslint-disable-next-line no-console
    console.warn(message);

    // TODO: Add info/stacktrace to a global variable somehow, so we can potentially provide an "upgrade readiness" greasemonkey script that can detail potential upgrade issues.

    return message;
};
