import React, { FC, HTMLAttributes, useEffect, useRef } from 'react';
import isString from 'lodash/isString';

import { prependClass, appendClass } from '../../utilities/classNames';

import Portal from '../portal';
import Testable from '../../interfaces/Testable';
import Breakpoints from '../../enums/breakpoints';
import disableBodyScroll from '../../hooks/disableBodyScroll';
import onClickOutside from '../../hooks/onClickOutside';

interface ModalProps extends Testable {
    onDismiss?: () => void,
    show?: boolean,
    'full-page'?: Breakpoints | boolean,
    className? : string,
    dismissAriaLabel: string
};

interface ModalItemProps extends HTMLAttributes<HTMLDivElement> {
}

interface IModal extends FC<ModalProps> {
    Header: FC<ModalItemProps>,
    Footer: FC<ModalItemProps>,
    Body: FC<ModalItemProps>
}

const Modal : IModal = ({ show, onDismiss, 'full-page': fullPage, className, children, dismissAriaLabel, 'data-testid' : testId, ...props }) => {
    const content = useRef<HTMLDivElement>(null);
    disableBodyScroll(show);
    onClickOutside(onDismiss, show, content);

    useEffect(() => {
        if (show) {
            content.current.focus();
        }
    }, [show]);

    const ariaLabel = dismissAriaLabel;

    let mergedClassName = prependClass(className, 'b-modal');
    let responsiveSuffix = '';

    if (fullPage) {
        responsiveSuffix = isString(fullPage) ? `-${fullPage}` : '';
        mergedClassName = appendClass(mergedClassName, `b-full-page${responsiveSuffix}`);
    }

    if (show) {
        mergedClassName = appendClass(mergedClassName, 'b-active');
    }

    // eslint-disable-next-line react/prop-types

    return (
        <Portal container="body">
            <div className={mergedClassName} role="dialog" tabIndex={-1} {...props}>
                <article className="b-content b-pt-0" role="document" ref={content}>
                    <i className="b-icon close b-pull-right b-mt-base b-mr-base" data-testid={`${testId}-dismiss`} role="button" onClick={onDismiss} aria-label={ariaLabel} />
                    {children}
                </article>
            </div>
        </Portal>
    );
};



export const Header : FC<ModalItemProps> = ({ className = '', children, ...props }) => {
    return (
        <header className={prependClass(className, 'b-header')} {...props}>
            {children}
        </header>
    );
};


export const Footer : FC<ModalItemProps> = ({ className = '', children, ...props}) => {
    return <footer className={prependClass(className, 'b-footer')} {...props}>{children}</footer>;
};

export const Body : FC<ModalItemProps> = ({ className, children, ...props}) => {
    return <section className={prependClass(className, 'b-body')} {...props}>{children}</section>;
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;

export default Modal;
