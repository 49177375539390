import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/button';
import Modal, {
    Body, Header
} from '../../components/modal';

export default class SubFilters extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        action: PropTypes.string,
        className: PropTypes.string,
        'test-id': PropTypes.string.isRequired,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
        show: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        action: null,
        show: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: props.show,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    dismiss() {
        this.setState({ open: false });
    }

    render() {
        const {
            // eslint-disable-next-line react/prop-types
            className, label, children, action
        } = this.props;

        const testId = this.props['test-id']; // eslint-disable-line react/destructuring-assignment

        const { open } = this.state;

        return (
            <div className={className}>
                <Button link className="b-dropdown-toggle b-show-xs" test-id={`${testId}-toggle`} onClick={this.toggle}>
                    {label}
                    <i className="b-icon chevron-down" />
                </Button>
                <Modal full-page="xs" test-id={`${testId}-modal`} onDismiss={this.dismiss} show={open} className="b-show-xs">
                    <Header>
                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
                        <i className="b-icon chevron-left b-show-inline-block b-clickable" data-testid={`${testId}-back-icon`} role="button" tabIndex={0} onClick={this.toggle} />

                        { action
                            && (
                                <Button link test-id={`${testId}-action`} className="b-show-inline" onClick={this.toggle}>
                                    {action}
                                    {' '}
                                </Button>
                            )
                        }
                    </Header>
                    <Body>
                        {children}
                    </Body>
                </Modal>
                <div className="b-hide-xs">
                    {children}
                </div>
            </div>
        );
    }
}
