import debug, { IDebugger } from 'debug';

export enum LogLevel {
    FATAL = 'FATAL',
    ERROR = 'ERROR',
    WARN = 'WARN',
    VERBOSE = 'VERBOSE',
    INFO = 'INFO'
};

export default class DebugLogger {
    private logger: debug.Debugger;

    constructor(log : IDebugger | string) {
        if (typeof(log) === 'string') {
            this.logger = debug(log);
        } else {
            this.logger = log;
        }
    }

    warn(message : string, ...args : any) {
        return this.log(message, LogLevel.WARN, ...args);
    }

    error(message : string, error : Error, ...args : any) {
        return this.log(message, LogLevel.ERROR, error, ...args);
    };

    fatal(message : string, error : Error, ...args : any) {
        return this.log(message, LogLevel.FATAL, error, ...args);
    }

    verbose(message : string, ...args : any) {
        return this.log(message, LogLevel.VERBOSE, ...args);
    }

    info(message : string, ...args : any) {
        return this.log(message, LogLevel.INFO, ...args);
    }

    extend(namespace : string) {
        return new DebugLogger(this.logger.extend(namespace));
    }

    enable(pattern : LogLevel | string = 'BUI*') {
        if (pattern in LogLevel) {
            debug.enable(`*[${pattern}]*`);
        } else {
            debug.enable(pattern);
        }
    }

    private log(message : string, level : LogLevel, ...args : any) {
        const logger = this.logger.extend(`[${level}]`);

        logger(message, ...args);

        return logger;
    }
}
