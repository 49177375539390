import mapProps from 'recompose/mapProps';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';

import { appendClass, prependClass, classString } from './classNames';

const classNameMapper = (newClass, transformer) => mapProps((initial) => {
    const { className, ...props } = initial;
    let newClassString = '';

    if (isFunction(newClass)) {
        const result = newClass(initial);
        if (isArray(result)) {
            newClassString = classString(result);
        } else {
            newClassString = result;
        }
    }

    if (isString(newClass)) {
        newClassString = newClass;
    }

    if (isArray(newClass)) {
        newClassString = classString(newClass);
    }

    return { ...props, className: transformer(className, newClassString) };
});

export const appendClassName = (newClass) => classNameMapper(newClass, appendClass);
export const prependClassName = (newClass) => classNameMapper(newClass, prependClass);
export const overrideClassName = (newClass) => classNameMapper(newClass, (c, nc) => nc);

export const omit = (toOmit) => mapProps((initial) => {
    const omittedProps = [];

    if (isFunction(toOmit)) {
        const result = toOmit(initial);
        if (isArray(result)) {
            result.forEach((i) => omittedProps.push(i));
        } else {
            omittedProps.push(result);
        }
    }

    if (isArray(toOmit)) {
        toOmit.forEach((i) => omittedProps.push(i));
    }

    if (isString(toOmit)) {
        omittedProps.push(toOmit);
    }

    const props = { ...initial };

    omittedProps.forEach((prop) => {
        delete props[prop];
    });

    return props;
});

export const setHtml = (html) => mapProps((initial) => ({
    ...initial,
    dangerouslySetInnerHTML: {
        __html: html,
    },
}));
