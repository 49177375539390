import DebugLogger from './utilities/logger';
import { version } from '../package.json';

/* istanbul ignore next */
if (!global.BUI) {
    global.BUI = {
        logging: new DebugLogger('BUI'),
        version: version
    };
}

// All internal logging needs to be surfaced from the same logger instance in order for performance timings to be accurately reported.
// This also gives customers an easy way to turn logging on and off without having to mess with localstorage:
export const Logger = global.BUI.logging;
