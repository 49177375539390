import { useEffect } from 'react';

const disableBodyScroll = (/* istanbul ignore next */ enabled : boolean = true) => {
    useEffect(() => {
        if (enabled) {
            document.body.classList.add('b-noscroll');
        } else {
            document.body.classList.remove('b-noscroll');
        }

        return () => {
            document.body.classList.remove('b-noscroll');
        }

    }, [enabled])
};

export default disableBodyScroll;
