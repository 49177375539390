import React from 'react';
import PropTypes from 'prop-types';
import testable, { propTypes as testableProps } from '../../enhancers/testable';
import { prependClass } from '../../utilities/classNames';

const Checkbox = testable(true)(({
    label, id, className, disabled, checked, helpText, indeterminate, ...props
}) => {
    const css = prependClass('b-checkbox', className);

    return (
        <div className={css}>
            <label htmlFor={id} className={disabled ? 'b-text-disabled' : ''}>
                <input
                    type="checkbox"
                    id={id}
                    disabled={disabled}
                    checked={checked}
                    // eslint-disable-next-line no-return-assign
                    ref={(el) => el && (
                        // eslint-disable-next-line no-param-reassign
                        el.indeterminate = indeterminate
                    )}
                    {...props}
                />
                {label}
                <i className="b-custom" />
                {helpText !== ''
                    && <p className="b-help">{helpText}</p>}
            </label>
        </div>
    );
});

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    className: PropTypes.string,
    indeterminate: PropTypes.bool,
    ...testableProps
};

Checkbox.defaultProps = {
    helpText: '',
    className: '',
    indeterminate: false
};

export default Checkbox;
