import { useEffect, useCallback } from 'react';

const onClickOutside = (callback : (e : Event) => void, enabled : boolean = true, node? : any) => {
    const handleClick = useCallback((e : Event) => {
        if (node && node.current.contains(e.target)) {
            return; // Do nothing, the clicked node is within the container
        }

        callback(e);
    }, [node])

    useEffect(() => {
        if (enabled) {
            document.addEventListener('mousedown', handleClick);
            document.addEventListener('touchstart', handleClick)
        } else {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        }

        // We can ignore coverage here, because it's both difficult to test, and built in behavior in react we can trust.
        // This function will be executed as part of the unmount cycle of components it's used on, and therefore clean up unnecessary event handlers.
        /* istanbul ignore next */
        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        }

    }, [enabled])

    return handleClick;
};

export default onClickOutside;
