import React from 'react';
import PropTypes from '../../utilities/prop-types';
import { appendClass, prependClass } from '../../utilities/classNames';
import testable from '../../enhancers/testable';

const ListWrapper = ({ ordered, children, ...props }) => {
    if (ordered) {
        return <ol {...props}>{children}</ol>;
    }

    return <ul {...props}>{children}</ul>;
};

ListWrapper.propTypes = {
    ordered: PropTypes.bool,
    children: PropTypes.children.isRequired
};

ListWrapper.defaultProps = {
    ordered: false
};

export const ListItem = testable(false)(({ className: customClassNames, children, ...props }) => {
    const className = prependClass(customClassNames, 'b-item');

    return <li className={className} {...props}>{children}</li>;
});

ListItem.propTypes = {
    children: PropTypes.children.isRequired,
    className: PropTypes.string
};

ListItem.defaultProps = {
    className: ''
};

export const SelectableListItem = testable(false)(({ className: customClassNames, children, ...props }) => {
    const className = prependClass(customClassNames, 'b-item b-selectable');

    return <li className={className} {...props}>{children}</li>;
});

ListItem.propTypes = {
    children: PropTypes.children.isRequired,
    className: PropTypes.string
};

ListItem.defaultProps = {
    className: ''
};

const List = testable(false)(({
    unstyled, inline, hover, group, ordered, children, className: customClassNames, ...props
}) => {
    let className = prependClass(customClassNames, 'b-list');

    if (unstyled) {
        className = appendClass(className, 'b-unstyled');
    }

    if (inline) {
        className = appendClass(className, 'b-inline');
    }

    if (group) {
        className = appendClass(className, 'b-list-group');
    }

    if (hover) {
        className = appendClass(className, 'b-hover');
    }

    return <ListWrapper ordered={ordered} className={className} {...props}>{children}</ListWrapper>;
});

List.propTypes = {
    ordered: PropTypes.bool,
    unstyled: PropTypes.bool,
    inline: PropTypes.bool,
    group: PropTypes.bool,
    hover: PropTypes.bool,
    children: PropTypes.children.isRequired,
    className: PropTypes.string
};

List.defaultProps = {
    ordered: false,
    unstyled: false,
    inline: false,
    hover: false,
    group: false,
    className: ''
};

List.Item = ListItem;
List.SelectableItem = SelectableListItem;

export default List;
