import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

// styles
import './loading-overlay.scss';

class LoadingOverlay extends React.PureComponent {
    static propTypes = {
        loading: PropTypes.bool,
    };

    static defaultProps = {
        loading: false,
    };

    render() {
        const { loading } = this.props;
        const show = loading ? 'show' : '';

        return (
            <div className={`b-loading-overlay ${show}`} role="alert" aria-busy={show}>
                <Icon type="loader" />
            </div>
        );
    }
}

export default LoadingOverlay;
