import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import testable from '../../enhancers/testable';

export class ToastNotification extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        children: PropTypes.node.isRequired,
        heading: PropTypes.string,
        type: PropTypes.string,
        onClose: PropTypes.func,
        className: PropTypes.string
    };

    static defaultProps = {
        show: false,
        onClose: /* istanbul ignore next */ () => {},
        heading: null,
        type: 'info',
        className: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
        };

        this.onClick = this.onClick.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line no-unused-vars
        const { show } = this.state;

        if (show !== nextProps.show) {
            this.setState({ show }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    onClick() {
        this.setState({ show: false });
        const { onClose } = this.props;
        onClose();
    }

    render() {
        const {
            children,
            heading,
            type,
            className, // eslint-disable-line no-unused-vars
            show : omitShow, // eslint-disable-line no-unused-vars
            ...p
        } = this.props;

        const { show } = this.state;

        let headingElement;

        if (heading) {
            headingElement = <h4>{heading}</h4>;
        } else {
            headingElement = null;
        }

        let notificationType = type;
        if (type === 'error') {
            notificationType = 'danger';
        }

        return (
            <div className={`b-toast-notification-container${show ? ' b-active' : ''}`}>
                <div className={`b-toast-notification b-${notificationType}${show ? ' b-active' : ''}`} role="alert" {...p}>
                    {headingElement}
                    <div>
                        <Icon type="close" onClick={this.onClick} />
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default testable(false)(ToastNotification);
