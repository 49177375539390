export function appendClass(str, newClass) {
    const newStr = (str || '').trim();
    return `${newStr} ${newClass}`.trim();
}

export function prependClass(str, newClass) {
    const newStr = (str || '').trim();
    return `${newClass} ${newStr}`.trim();
}

export function classString(arr) {
    return arr.join(' ').trim();
}
