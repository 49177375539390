import React from 'react';
import PropTypes from 'prop-types';
import { prependClass } from '../../utilities/classNames';
import { Title as BTitle } from '../simple-layout';
import FormGroup from '../../components/form-group';
import FormInput from '../../components/form-input';
import _SubFilters from './sub-filters';

export const SubFilters = _SubFilters;

export const Title = BTitle;

export const Content = (props) => {
    const { className, children, ...p } = props;
    return <article className={prependClass(className, 'b-main')} {...p}>{children}</article>;
};

Content.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Content.defaultProps = {
    className: ''
};

export const ActionBar = (props) => {
    const { className, children, ...p } = props;
    return <aside className={prependClass(className, 'b-list-action-bar')} {...p}>{children}</aside>;
};

ActionBar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

ActionBar.defaultProps = {
    className: ''
};

export const Recap = (props) => {
    const { className, children, ...p } = props;
    return <div className={prependClass(className, 'b-list-recap')} {...p}>{children}</div>;
};

Recap.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Recap.defaultProps = {
    className: ''
};

export const ActionButtons = (props) => {
    const { className, children, ...p } = props;
    return <div className={prependClass(className, 'b-hide-xs b-pull-right')} {...p}>{children}</div>;
};

ActionButtons.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

ActionButtons.defaultProps = {
    className: ''
};

export const Filters = (props) => {
    const { className, children, ...p } = props;
    return <aside className={prependClass(className, 'b-list-filters')}><form {...p}>{children}</form></aside>;
};

Filters.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Filters.defaultProps = {
    className: ''
};

export const Utilities = (props) => {
    const { className, children, ...p } = props;

    return <section className={prependClass(className, 'b-list-utility')} {...p}>{children}</section>;
};

Utilities.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Utilities.defaultProps = {
    className: ''
};

export const SearchBox = (props) => {
    const {
        label, id, ...p
    } = props;

    return (
        <FormGroup error={false} className="b-pull-left-xs">
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor={id} className="b-hide-xs">{label}</label>
            <FormInput id={id} type="search" aria-label={label} {...p} />
        </FormGroup>
    );
};

SearchBox.propTypes = {
    label: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired
};

export const Results = (props) => {
    const { className, children, ...p } = props;
    return <div className={prependClass(className, 'b-list-results')} {...p}>{children}</div>;
};

Results.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Results.defaultProps = {
    className: ''
};

const FilteredListLayout = (props) => {
    const {
        className,
        children,
        fluid,
        ...p
    } = props;

    const containerClass = fluid ? 'b-container-fluid' : 'b-container';

    return (
        <div className="b-layout-list">
            <main className={prependClass(className, containerClass)} {...p}>
                {children}
            </main>
        </div>
    );
};

FilteredListLayout.propTypes = {
    className: PropTypes.string,
    fluid: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

FilteredListLayout.defaultProps = {
    className: '',
    fluid: false,
};

export default FilteredListLayout;
