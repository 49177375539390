import React from 'react';
import PropTypes from 'prop-types';
import { prependClass } from '../../utilities/classNames';

export const Title = (props) => {
    const { className, children, ...p } = props;
    return <header className={prependClass(className, 'b-title')} {...p}>{children}</header>;
};

Title.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Title.defaultProps = {
    className: ''
};

export const Content = (props) => {
    const { className, children, ...p } = props;
    return <article className={prependClass(className, 'b-content b-mv-0')} {...p}>{children}</article>;
};

Content.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Content.defaultProps = {
    className: ''
};

const SimpleLayout = (props) => {
    const {
        className,
        children,
        fluid,
        ...p
    } = props;

    const containerClass = fluid ? 'b-container-fluid' : 'b-container';

    return (
        <div className="b-layout b-p-0">
            <main className={prependClass(className, containerClass)} {...p}>
                {children}
            </main>
        </div>
    );
};

SimpleLayout.propTypes = {
    className: PropTypes.string,
    fluid: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SimpleLayout.defaultProps = {
    className: '',
    fluid: false,
};

export default SimpleLayout;
