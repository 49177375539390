import React from 'react';
import PropTypes from '../../utilities/prop-types';

import testable from '../../enhancers/testable';

import { prependClass, appendClass } from '../../utilities/classNames';

export const iconTypes = [
    'more-vertical',
    'more-horizontal',
    'pencil',
    'search',
    'chevron-up',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'caret',
    'caret-right',
    'loader',
    'close',
    'open',
];

export const propTypes = {
    type: PropTypes.oneOf(iconTypes).isRequired,
    small: (props) => {
        const { type, small } = props;

        if (small) {
            if (type.startsWith('chevron') || type === 'open') {
                return null;
            }

            return new Error('Small is only supported on chevrons and open icons.');
        }
    },
    large: (props) => {
        const { type, large } = props;

        if (large) {
            if (type.startsWith('chevron')) {
                return null;
            }

            return new Error('Large is only supported on chevrons.');
        }
    },
    inverted: PropTypes.bool,
    className: PropTypes.string
};

export const defaultProps = {
    small: false,
    large: false,
    className: ''
};

const Icon = testable(false)(({
    type, small, large, inverted, className: customClass, ...props
}) => {
    let className = prependClass(customClass, `b-icon ${type}`);

    if (small) {
        className = appendClass(className, 'small');
    }

    if (large) {
        className = appendClass(className, 'large');
    }

    if (inverted) {
        className = appendClass(className, 'b-inverted');
    }

    return (
        <i className={className} {...props} />
    );
});

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
