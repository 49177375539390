import React from 'react';
import PropTypes from 'prop-types';

import showable from '../../enhancers/showable';

import { prependClass, appendClass } from '../../utilities/classNames';

const FormGroup = (props) => {
    const {
        children, helpText, error, errorText, className, loading,
    } = props;

    const Showable = showable('span');

    let css = prependClass('b-form-group', className);

    if (error) {
        css = appendClass('b-error', css);
    }
    if (loading) {
        css = appendClass('b-loading', css);
    }

    return (
        <fieldset className={css}>
            {children}
            <Showable show={!!helpText} className="b-help">{helpText}</Showable>
            <Showable show={error} className="b-error">{errorText}</Showable>
        </fieldset>
    );
};

FormGroup.propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    helpText: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

FormGroup.defaultProps = {
    error: false,
    loading: false,
    className: '',
    errorText: '',
    helpText: ''
};

export default FormGroup;
