import PropTypes from 'prop-types';
import setPropTypes from 'recompose/setPropTypes';
import setDefaultProps from 'recompose/defaultProps';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import { appendClassName, omit } from '../../utilities/composers';

export const propTypes = {
    show: PropTypes.bool,
};

export const defaultProps = {
    show: true,
};

const destructive = branch((props) => props.show === false, renderNothing);

const nonDestructive = branch((props) => props.show === false, appendClassName('b-hide'));

export default compose(
    setPropTypes(propTypes),
    setDefaultProps(defaultProps),
    nonDestructive,
    omit('show'),
);

export const removable = compose(
    setPropTypes(propTypes),
    setDefaultProps(defaultProps),
    destructive,
    omit('show'),
);
